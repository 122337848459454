$(document).ready(function(){
  getLatestArticle();
  
})
function getLatestArticle(){
  var limitarticle = 8,
  offsetId = 0,
  param = {'offset_id': offsetId, 'limit': limitarticle};
  actionWebApiRestrict('/v1/stream/home', param, 'GET').done(function(json) {
    if (json.status == 600) {
      streamData = json.data;
      drawFirstHomeArticle(streamData[0])
      getTopBanner()
      drawNextHomeArticle(streamData)
      $('.lazy-cover-next').lazyload()
    }
  })
}
function getTopBanner(){
  actionWebApiRestrict('/v1/top-banner/all',{}, 'GET').done(function(json) {
    if (json.status == 600) {
      banners = json.data;
      var totalBanner = banners.length - 1;
      var append = '';
      for(i = 0; i <= totalBanner; i++){
          var newTab = ''
          if(banners[i].open_new_tab == true){
            newTab = 'target="_blank"'
          }
          var setclass = 'lazy-cover-banner';

          append += '<div class="home-latest-article-container">';
          append += '  <div class="first-latest-article">';
          append += '    <a href="'+banners[i].link_web+'" '+newTab+' class="set-height-banner">';
          var setImg
          if($(window).width() > 768){
            setImg = banners[i].original.url
          }else{
            setImg = banners[i].mobile.url
          }
          append += '      <img src="data:image/gif;base64,R0lGODdhAQABAPAAAMPDwwAAACwAAAAAAQABAAACAkQBADs=" alt="banner cover" data-original="'+setImg+'" class="'+setclass+'">';
          append += '    </a>';
          append += '  </div>';
          append += '</div>';
      }
      $('#home-latest-article-container').append(append)
      $(".variable").slick({
        dots: true,
        lazyLoad: 'ondemand', // ondemand progressive anticipated
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000
      });
      $('.slick-prev').html("")
      $('.slick-next').html("")
      $('.slick-dots button').html("")
      $('.lazy-cover-banner').lazyload().trigger("appear")
    }
  })
}
function drawFirstHomeArticle(value){
  var append = '';
  var setclass = 'lazy-cover-banner';
  append += '<div class="home-latest-article-container">';
  append += '  <div class="first-latest-article">';
  append += '    <a href="'+value.url+'">';
  var setImg
      if($(window).width() > 768){
        setImg = value.cover.original
      }else{
        setImg = value.editor_cover.original
      }
  var lengthTitle = value.title.length;
  var setTitle = ''
  if(lengthTitle > 100){ 
    setTitle = htmlSubstring(value.richtext_title,100)+'...'
  }else{
    setTitle = value.richtext_title
  }
  var lengthTaicing = value.taicing.length;
  var trimTaicing = value.taicing.substring(0,200)
  var addDot = ''
  if(lengthTaicing > 200){ addDot = '...'}
  append += '      <img src="data:image/gif;base64,R0lGODdhAQABAPAAAMPDwwAAACwAAAAAAQABAAACAkQBADs=" alt="banner cover" data-original="'+setImg+'" class="'+setclass+'">';
  append += '      <span class="first-latest-article-background">&nbsp;</span>';
  append += '      <span class="first-latest-article-layout">';
  append += '        <span class="first-latest-article-detail">';
  append += '          <span class="first-latest-article-title">'+setTitle+'</span>';
  append += '          <span class="first-latest-article-description">'+trimTaicing+addDot+ '</span>';
  append += '        </span>';
  append += '      </span>';
  append += '    </a>';
  append += '  </div>';
  append += '</div>';
  $('#home-latest-article-container').append(append)
}
function drawNextHomeArticle(stream){
  var i = 1,append = '';
  $.each(stream, function( index, value ) {
    if(i>1){
      if(isMuseumActive){
        if(i == 4){
          append += '<a href="/historipedia/museum" class="next-latest-list link-museum"><img src="https://d220hvstrn183r.cloudfront.net/museum/cover-museum-ads-medium.jpg" alt="Historipedia Museum"></a>'
        }
      }
      var lengthTitle = value.title.length;
      var setTitle = ''
      if(lengthTitle > 100){ 
        setTitle = htmlSubstring(value.richtext_title,100)+'...'
      }else{
        setTitle = value.richtext_title
      }
      var lengthTaicing = value.taicing.length;
      var trimTaicing = value.taicing.substring(0,200)
      var addDot = ''
      append += '<a class="next-latest-list" href="'+value.url+'" >';
      append += '  <span class="latest-list-image"><img class="lazy-cover-next" data-original="'+value.cover.small+'" alt="cover image"></span>';
      append += '  <span class="latest-list-detail">';
      append += '    <span class="latest-list-title"><h3>'+setTitle+'</h3></span>';
      append += '    <span class="latest-list-description"><p>'+trimTaicing+addDot+'</p></span>';
      append += '  </span>';
      append += '</a>';
    }
    i++;
  });
  $('#home-next-article-container').html(append)
}
